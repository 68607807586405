export enum EFeatureFlags {
  AppointmentRemindersEnabled = "AppointmentRemindersEnabled",
  AutomatedFormsEnabled = "AutomatedFormsEnabled",
  DesktopNotificationsEnabled = "DesktopNotificationsEnabled",
  DocMgrLoadAllAppointmentsForToday = "DocMgrLoadAllAppointmentsForToday",
  DocumentManagerEnabled = "DocumentManagerEnabled",
  DocumentManagerPrefillPatientForms = "DocumentManagerPrefillPatientForms",
  DocumentManagerValidateFormsOnSubmit = "DocumentManagerValidateFormsOnSubmit",
  EmailCampaignsEnabled = "EmailCampaignsEnabled",
  EmailRemindersEnabled = "EmailRemindersEnabled",
  FillAppointmentsAsapEnabled = "FillAppointmentsAsapEnabled",
  FillAppointmentsAutomationEnabled = "FillAppointmentsAutomationEnabled",
  FillAppointmentsEnabled = "FillAppointmentsEnabled",
  FormBuilderEnabled = "FormBuilderEnabled",
  FormBuilderExportImportForms = "FormBuilderExportImportForms",
  FormBuilderGenerateDefaultNameForForms = "FormBuilderGenerateDefaultNameForForms",
  FormBuilderVersioning = "FormBuilderVersioning",
  FormTemplatesEnabled = "FormTemplatesEnabled",
  InsuranceVerificationV2Enabled = "InsuranceVerificationV2Enabled",
  InsuranceVerificationV3Enabled = "InsuranceVerificationV3Enabled",
  MetricsReportEnabled = "MetricsReportEnabled",
  NewPatientWelcomeEmailEnabled = "NewPatientWelcomeEmailEnabled",
  NewPatientWelcomeTextEnabled = "NewPatientWelcomeTextEnabled",
  NewPatientWelcomeTextFormAssignment = "NewPatientWelcomeTextFormAssignment",
  RecallRemindersEnabled = "RecallRemindersEnabled",
  RecallRemindersSchedulingEnabled = "RecallRemindersSchedulingEnabled",
  ReviewsEnabled = "ReviewsEnabled",
  InsuranceVerificationAutomationEnabled = "InsuranceVerificationAutomationEnabled",
  AppointmentsFlatTableForUIEnabled = "AppointmentsFlatTableForUIEnabled",
  FamilyMembersEnabled = "FamilyMembersEnabled",
  AutomaticFilingBeforeAppointmentEnabled = "AutomaticFilingBeforeAppointmentEnabled",
  ResetExpiredPendingFormEnabled = "ResetExpiredPendingFormEnabled",
  Release_20240925 = "Release_20240925",
  Release_20241023 = "Release_20241023",
  Release_20250115 = "Release_20250115",
  FormAssignmentQrCodeOptionEnabled = "FormAssignmentQrCodeOptionEnabled",
}
