import React, { FC, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import debounce from "lodash/debounce";

import {
  selectInOfficeSelectedPackagesIds,
  updateInOfficePackageName,
  updateInOfficeSelectedForms,
  updateInOfficeSelectedPackages,
  updateInOfficeSendTo,
  updateIsTabInitialized,
  selectIsQRCodeDisabled,
} from "./inOfficeModalSlice";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../../global/store";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { SendTo } from "../../SendTo/SendToWrapper";
import { EFormAssignmentNotificationType } from "../../../../../global/enums/EFormAssignmentNotificationType";

import { InOfficeFormsBox } from "./InOfficeFormsBox";
import { AddFormButton } from "../../common/AddFormButton";
import { SAssignFormWrapper } from "./styles";
import { IFormData } from "../../../../../global/services/settings/practiceSettings/formLibrary/FormTable.interface";
import { IFormPackage } from "../../../../settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/IFormPackage";
import { CustomPackageName } from "../../common/customPackageName/CustomPackageName";

import ImageContainer from "../../../../../global/components/ImageContainer/ImageContainer";

import { IInOfficePatientSectionProps } from "./types/IInOfficePatientSectionProps";
import { useGetFormAssignmentModalDataQuery } from "../../../../../global/services/document-manager/formAssignmentModalDataApi";
import { selectAvailableOptionsSendTo } from "../../common/patientSelectorModal/patientSelectorSlice";

const PatientSection: FC<IInOfficePatientSectionProps> = ({
  patientId,
  showCustomPackageName,
  storedPackageName,
  sendTo,
  appointmentId,
}): JSX.Element => {
  const storedAvailableOptions: EFormAssignmentNotificationType[] =
    useAppSelector(selectAvailableOptionsSendTo);
  const dispatch: AppDispatch = useAppDispatch();
  const isQRCodeDisabled = useAppSelector(selectIsQRCodeDisabled);
  const methods = useForm({
    defaultValues: {
      packageName: storedPackageName,
      sendTo,
      appointmentId,
    },
  });

  const { watch, setValue } = methods;
  const packageName: string = watch("packageName");

  const selectedPackagedIds: string[] = useAppSelector(
    selectInOfficeSelectedPackagesIds(patientId)
  );

  const { data: patientData, isFetching } = useGetFormAssignmentModalDataQuery(
    { patientId },
    {
      skip: !patientId,
    }
  );

  useEffect(() => {
    if (!sendTo) {
      if (hasNotifications(patientData)) {
        processLastDeviceSelection(patientData);
      } else {
        setDefaultOption();
      }
    } else {
      setValue("sendTo", sendTo);
    }
  }, [sendTo, patientData, patientId, dispatch, setValue]);

  // Helper Functions

  function hasNotifications(data: any): boolean {
    return !!data?.data?.[0]?.formAssignment?.notifications?.length;
  }

  function processLastDeviceSelection(data: any) {
    try {
      const notifications = data.data[0].formAssignment.notifications;
      const lastDeviceSelection = notifications.at(-1);

      if (!lastDeviceSelection?.type) {
        logError("Last device selection type is invalid or undefined.");
        return;
      }

      if (lastDeviceSelection.type in EFormAssignmentNotificationType) {
        handleValidDeviceType(lastDeviceSelection.type);
      } else {
        logError(
          "Type not found in EFormAssignmentNotificationType:",
          lastDeviceSelection.type
        );
      }
    } catch (error) {
      logError("Error during processing notifications:", error);
    }
  }

  function handleValidDeviceType(type: string) {
    if (type in EFormAssignmentNotificationType && typeof type === "number") {
      if (!isQRCodeDisabled) {
        dispatch(
          updateInOfficeSendTo({
            patientId,
            sendTo: type as unknown as EFormAssignmentNotificationType,
          })
        );
        setValue("sendTo", type as unknown as EFormAssignmentNotificationType);
      } else if (isQRCodeDisabled && type === "QRCode") {
        dispatch(
          updateInOfficeSendTo({
            patientId,
            sendTo: EFormAssignmentNotificationType.Text,
          })
        );
        setValue("sendTo", EFormAssignmentNotificationType.Text);
      }
    } else {
      logError("Invalid type: ", type);
    }
  }

  function setDefaultOption() {
    const defaultOption = storedAvailableOptions[0];
    if (!isQRCodeDisabled && defaultOption) {
      dispatch(updateInOfficeSendTo({ patientId, sendTo: defaultOption }));
      setValue("sendTo", defaultOption);
    } else if (
      isQRCodeDisabled &&
      defaultOption === EFormAssignmentNotificationType.QRCode
    ) {
      dispatch(
        updateInOfficeSendTo({
          patientId,
          sendTo: EFormAssignmentNotificationType.Text,
        })
      );
      setValue("sendTo", EFormAssignmentNotificationType.Text);
    }
  }

  function logError(message: string, data?: any) {
    // eslint-disable-next-line no-console
    console.error(message, data);
  }

  const delayedUpdatePackageName = useCallback(
    debounce((newPackageName: string): void => {
      dispatch(
        updateInOfficePackageName({ packageName: newPackageName, patientId })
      );
    }, 500),
    [patientId]
  );
  // set isTabInitialized flag for current familyMember before switching to another tab
  useEffect(() => {
    return (): void => {
      dispatch(updateIsTabInitialized({ patientId, isTabInitialized: true }));
    };
  }, [patientId]);

  useEffect((): void => {
    setValue("packageName", storedPackageName);
  }, [storedPackageName]);

  useEffect((): void => {
    if (methods.getValues("sendTo") !== sendTo) {
      setValue("sendTo", sendTo);
    }
  }, [sendTo]);

  useEffect((): void => {
    delayedUpdatePackageName(packageName);
  }, [packageName]);

  const handleFormSelection = (form: IFormData): void => {
    dispatch(updateInOfficeSelectedForms({ patientId, form }));
  };

  const handlePackageSelection = (formPackage: IFormPackage): void => {
    dispatch(
      updateInOfficeSelectedPackages({ package: formPackage, patientId })
    );
  };

  const updateSendTo = useCallback(
    (value: EFormAssignmentNotificationType) => {
      return updateInOfficeSendTo({ patientId, sendTo: value });
    },
    [dispatch, patientId]
  );

  if (isFetching && !patientData)
    return (
      <Box width={450}>
        <ImageContainer marginTop={"0"} />
      </Box>
    );

  return (
    <FormProvider {...methods}>
      <SAssignFormWrapper>
        <SendTo
          patientId={patientId}
          isRequiredDoB={patientData?.requiresPatientDob ?? true}
          updateSendTo={updateSendTo}
        />
        <Box>
          <AddFormButton
            handleFormSelection={handleFormSelection}
            handlePackageSelection={handlePackageSelection}
            selectedPackagedIds={selectedPackagedIds}
          />
          {showCustomPackageName ? <CustomPackageName /> : null}
          <InOfficeFormsBox patientId={patientId} />
        </Box>
      </SAssignFormWrapper>
    </FormProvider>
  );
};

export const InOfficePatientSection = React.memo(PatientSection, areEqualProps);
