import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SelectChangeEvent } from "@mui/material";

import {
  selectAvailableOptionsSendTo,
  updateAvailableOptionsSendTo,
} from "../common/patientSelectorModal/patientSelectorSlice";
import {
  updateInOfficeSendTo,
  updateSelectedDevice,
  selectInOfficePatientSendTo,
  selectInOfficeSelectedDevice,
  selectIsQRCodeDisabled,
} from "../inOfficePatients/FormAssignerModal/inOfficeModalSlice";

import { useAppSelector } from "../../../../global/hooks/useTypedRedux.hook";
import { TDevice } from "../../../../global/types/settings/DeviceSettings/TDevice.type";
import { SecondaryText } from "../../../../global/components/SecondaryText/SecondaryText";
import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { selectIsAssignFormModalVisible } from "../inOfficePatients/inOfficePatientsSlice";
import {
  DEVICE_OPTION,
  getOptions,
  PATIENT_TEXT_OPTION,
  PATIENT_EMAIL_OPTION,
  QR_CODE_OPTION,
} from "./getSendToOptions";
import { SendToView } from "./SendToView";
import { SendToTooltip } from "./SendToTooltip";
import {
  InOfficeOptionsArgs,
  IInOfficePatientsSendTo,
} from "./SendTo.interfaces";
import { EFormAssignmentNotificationType } from "../../../../global/enums/EFormAssignmentNotificationType";
import { SCustomMenuWrapper, SCustomMenuItem } from "./styles";
import { SEND_TO } from "./constants";
import { useGetFormAssignmentModalDataQuery } from "../../../../global/services/document-manager/formAssignmentModalDataApi";
import { EFeatureFlags } from "../../../../global/enums/EFeatureFlags";
import { useGetFeatureFlag } from "../../../../global/hooks/useGetFeatureFlag";

const SendTo = ({
  patientId,
  dispatch,
  setValue,
  watch,
  patientTextInformation,
  patientEmailInformation,
  onChange,
}: IInOfficePatientsSendTo) => {
  const isQRCDisabled = useAppSelector(selectIsQRCodeDisabled);
  const { isEnabled: isRelease_20250115 } = useGetFeatureFlag(
    EFeatureFlags.Release_20250115
  );
  const [isSendToAlreadySet, setIsSendToAlreadySet] = useState(false);
  const [currentSendTo, setCurrentSendTo] = useState<number | null>(null);
  const sendToValue: EFormAssignmentNotificationType = useAppSelector(
    selectInOfficePatientSendTo(patientId)
  );
  const IS_QR_DISABLED = isQRCDisabled && isRelease_20250115;
  const isFormAssignerModalOpen: boolean = useAppSelector(
    selectIsAssignFormModalVisible
  );
  const storedAvailableOptions: EFormAssignmentNotificationType[] =
    useAppSelector(selectAvailableOptionsSendTo);

  const { data: patientData, isFetching } = useGetFormAssignmentModalDataQuery(
    { patientId },
    {
      skip: !patientId,
    }
  );
  const getLastSendToType = (
    patientData: any
  ): EFormAssignmentNotificationType | null => {
    if (!patientData?.data?.[0]?.formAssignment?.notifications) {
      return null; // Return null if notifications are not available
    }

    const lastSendToType =
      patientData.data[0].formAssignment.notifications.at(-1);
    if (lastSendToType?.type in EFormAssignmentNotificationType) {
      const initialType = EFormAssignmentNotificationType[lastSendToType.type];
      return EFormAssignmentNotificationType[initialType];
    }
    return null; // Return null if no valid type is found
  };

  const selectedDevice = useAppSelector(
    selectInOfficeSelectedDevice(patientId)
  );
  const hasDevices = Boolean(patientData?.enabledDevices?.length);
  useEffect(() => {
    const setAvailableOptions = (
      isTextDisabled: boolean,
      isEmailDisabled: boolean
    ): void => {
      const sendToAvailableOptions: EFormAssignmentNotificationType[] = [];
      if (!IS_QR_DISABLED) {
        sendToAvailableOptions.push(QR_CODE_OPTION.value);
      }
      if (patientData?.enabledDevices?.length)
        sendToAvailableOptions.push(DEVICE_OPTION.value);
      if (!isTextDisabled)
        sendToAvailableOptions.push(PATIENT_TEXT_OPTION.value);
      if (!isEmailDisabled)
        sendToAvailableOptions.push(PATIENT_EMAIL_OPTION.value);

      if (
        sendToAvailableOptions.length &&
        sendToAvailableOptions.length !== storedAvailableOptions?.length
      ) {
        dispatch(updateAvailableOptionsSendTo(sendToAvailableOptions));
      }
    };

    setAvailableOptions(
      patientTextInformation.disabled,
      patientEmailInformation.disabled
    );
  }, [
    patientTextInformation.disabled,
    patientEmailInformation.disabled,
    storedAvailableOptions,
    patientData?.enabledDevices,
  ]);
  useEffect((): void => {
    if (!patientData) return;

    const initializeSendTo = () => {
      const previousSelection = getLastSendToType(patientData);
      let defaultOption;

      if (
        storedAvailableOptions?.length === 1 &&
        IS_QR_DISABLED &&
        storedAvailableOptions[0] === QR_CODE_OPTION.value
      ) {
        defaultOption = DEVICE_OPTION.value;
      } else if (storedAvailableOptions?.length >= 1) {
        defaultOption = storedAvailableOptions[0];
      }

      const selectionToUse =
        previousSelection && !IS_QR_DISABLED
          ? previousSelection
          : defaultOption;

      if (selectionToUse) {
        setCurrentSendTo(selectionToUse);
        setValue(SEND_TO, selectionToUse);
        dispatch(updateInOfficeSendTo({ patientId, sendTo: selectionToUse }));
      }
    };

    const syncWithUserChoice = () => {
      if (currentSendTo !== sendToValue) {
        setCurrentSendTo(sendToValue);
      }
    };

    if (!isSendToAlreadySet && storedAvailableOptions.length > 0) {
      initializeSendTo();
      setIsSendToAlreadySet(true);
    } else {
      syncWithUserChoice();
    }
  }, [
    patientData,
    storedAvailableOptions,
    isSendToAlreadySet,
    currentSendTo,
    sendToValue,
    patientId,
  ]);

  useEffect(() => {
    if (isFetching) return;
    const noDeviceSelected = () => {
      dispatch(updateSelectedDevice({ selectedDevice: null, patientId }));
    };

    const selectSpecificDevice = (device: TDevice) => {
      if (device) {
        dispatch(updateSelectedDevice({ selectedDevice: device, patientId }));
        setValue(SEND_TO, DEVICE_OPTION.value);
      }
    };

    const handlePreviousDevice = () => {
      if (patientData?.previousDevice?.enabled) {
        selectSpecificDevice(patientData.previousDevice);
      } else {
        noDeviceSelected();
      }
    };

    // Main execution
    if (hasDevices) {
      if (!selectedDevice && !patientData?.previousDevice?.enabled) {
        noDeviceSelected();
      } else if (!selectedDevice && patientData?.previousDevice?.enabled) {
        handlePreviousDevice();
      } else if (selectedDevice) {
        selectSpecificDevice(selectedDevice);
      } else if (
        watch(SEND_TO) !== DEVICE_OPTION.value &&
        !isRelease_20250115
      ) {
        setValue(SEND_TO, DEVICE_OPTION.value);
      }
    }
  }, [
    hasDevices,
    selectedDevice,
    isFormAssignerModalOpen,
    patientData?.enabledDevices,
  ]);

  const renderDevicesOrNotFoundMessage = useCallback((): JSX.Element => {
    if (!hasDevices) return <SecondaryText>No devices found.</SecondaryText>;

    const isDeviceOptionSelected: boolean = sendToValue === DEVICE_OPTION.value;

    const handleDeviceClick = (e: SelectChangeEvent): void => {
      const { value: id } = e.target;
      const deviceClicked: TDevice = patientData?.enabledDevices.find(
        (device: TDevice): boolean => device.id === id
      );
      if (deviceClicked) {
        dispatch(
          updateSelectedDevice({
            selectedDevice: deviceClicked,
            patientId: patientId,
          })
        );
      }
    };
    return (
      <SCustomMenuWrapper
        disabled={!isDeviceOptionSelected}
        variant="standard"
        onChange={handleDeviceClick}
        value={selectedDevice?.id || "placeholder"}
        MenuProps={{ PaperProps: { style: { maxWidth: 500 } } }}
      >
        {!selectedDevice && !patientData?.previousDevice?.enabled && (
          <SCustomMenuItem
            value="placeholder"
            disabled={true}
            title="Select linked device"
            key=""
            isSelected={false}
          >
            <span
              style={{
                color: "#E5E5E5",
              }}
            >
              Select linked Device
            </span>
          </SCustomMenuItem>
        )}
        {patientData?.enabledDevices.map((device: TDevice) => (
          <SCustomMenuItem
            isSelected={selectedDevice?.name === device.name}
            title={device.name}
            key={device.id}
            value={device.id}
          >
            {device.name}
          </SCustomMenuItem>
        ))}
      </SCustomMenuWrapper>
    );
  }, [selectedDevice, hasDevices, sendToValue]);
  const optionsConditions: InOfficeOptionsArgs = useMemo(() => {
    return {
      deviceCondition: { disabled: !hasDevices },
      qrCodeCondition: { disabled: IS_QR_DISABLED },
      textCondition: { disabled: patientTextInformation?.disabled },
      emailCondition: { disabled: patientEmailInformation?.disabled },
      tooltips: {
        patientText: (
          <SendToTooltip missedFields={patientTextInformation?.missedFields} />
        ),
        email: (
          <SendToTooltip missedFields={patientEmailInformation?.missedFields} />
        ),
      },
    };
  }, [hasDevices, patientTextInformation, patientEmailInformation]);

  const handleRadioChange = (newValue: EFormAssignmentNotificationType) => {
    setCurrentSendTo(newValue); // Update local state
    setValue(SEND_TO, newValue); // Sync with form state
    dispatch(updateInOfficeSendTo({ patientId, sendTo: newValue })); // Update Redux
    if (newValue === DEVICE_OPTION.value) {
      const currentSelectedDevice: TDevice = watch(
        "selectedDevice"
      ) as unknown as TDevice;
      if (selectedDevice) {
        dispatch(
          updateSelectedDevice({
            selectedDevice: currentSelectedDevice,
            patientId,
          })
        );
      }
    }
    onChange(newValue); // Notify parent component if needed
  };

  return (
    <SendToView
      options={getOptions(optionsConditions)}
      setValue={setValue}
      sendTo={currentSendTo}
      onChange={handleRadioChange}
      renderDevicesOrNotFoundMessage={renderDevicesOrNotFoundMessage}
    />
  );
};

export const InOfficePatientsSendTo = React.memo(SendTo, areEqualProps);
