import React, { FC, useMemo, useState } from "react";
import { Theme, useTheme } from "@mui/material";

import {
  useAssignFormsMutation,
  useDeviceNotificationMutation,
  useNotificationMutation,
  useQRCodeNotificationMutation,
  useSaveFormAssignmentMutation,
} from "../../../../../global/services/document-manager/formAssignmentsApi";
import {
  selectInOfficePatientSelectedForms,
  selectInOfficePatientSendTo,
  selectInOfficePackageName,
  selectInOfficePatientAppointmentId,
  selectFamilyMembersWithForms,
  selectFamilyMembersWithoutForms,
  selectSubmitFormsData,
  selectWhereFormAssignerModalOpened,
  selectInOfficePatientSubmittedForms,
  selectIsFamilyMembersPackageNameValid,
  updateIsPatientSelectorModalVisible,
  updateIsInOfficeQRCodeModalVisible,
  updateInOfficeIsFormAssignerModalVisible,
  IRequestAssignForms,
} from "./inOfficeModalSlice";
import { updatePageIndex } from "../inOfficePatientsSlice";
import { updateSnackbar } from "../../../../authentication/globalMessagesSlice";

import { TForms } from "../../pendingForms/FormAssignerModal/pendingFormsModalsSlice";
import { AppDispatch } from "../../../../../global/store";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../global/hooks/useTypedRedux.hook";
import { ModalBodyView } from "../../common/ModalBodyView";
import { IPatientFamilyMember } from "../../../../../global/domains/patients/types/IPatientFamilyMember.interface";
import { EFormAssignmentNotificationType } from "../../../../../global/enums/EFormAssignmentNotificationType";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { InOfficePatientSection } from "./InOfficePatientSection";
import { FamilyMemberTabs } from "./FamilyMemberTabs";
import { ConfirmFamilyMembersWithoutFormsModal } from "./ConfirmFamilyMembersWithoutFormsModal";
import ImageContainer from "../../../../../global/components/ImageContainer/ImageContainer";
import { TDeviceNotification } from "../../../../../global/types/notification/TDeviceFormAssignmentNotificationsRequest.type";
import { EFormAssignButtons } from "./types/EFormAssignButtons";
import { SecondaryText } from "../../../../../global/components/SecondaryText/SecondaryText";
import { getShowCustomPackageName } from "../../common/getShowCustomPackageName";
import { selectAvailableOptionsSendTo } from "../../common/patientSelectorModal/patientSelectorSlice";
import { IModalBodyProps } from "./types/IModalBodyProps";
import { useGetFeatureFlag } from "../../../../../global/hooks/useGetFeatureFlag";
import { EFeatureFlags } from "../../../../../global/enums/EFeatureFlags";

const snackbarMessagesMapper = {
  [EFormAssignButtons.AddPatient]: "Form assigned",
  [EFormAssignButtons.AssignIncompleteFormsToDevice]: "Device assigned",
  [EFormAssignButtons.AddFormOrPackage]: "Forms added",
};

const ModalBody: FC<IModalBodyProps> = ({
  patientTabList,
  patient,
  setVisibility,
  patientsListRefetch,
  onQRCodeGenerated,
}): JSX.Element => {
  const { isEnabled: FormAssignmentQrCodeOptionEnabled } = useGetFeatureFlag(
    EFeatureFlags.FormAssignmentQrCodeOptionEnabled
  );
  const theme: Theme = useTheme();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const dispatch: AppDispatch = useAppDispatch();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isFormAssigning, setIsFormAssigning] = useState<boolean>(false);
  const [isTransitioningToQR, setIsTransitioningToQR] =
    useState<boolean>(false);
  const [assignForms] = useAssignFormsMutation();
  const [sendNotification] = useNotificationMutation();
  const [sendQRNotification] = useQRCodeNotificationMutation();
  const [sendDeviceNotification] = useDeviceNotificationMutation();
  const [saveAssignedForms] = useSaveFormAssignmentMutation();

  const { isEnabled: isFamilyMembersEnabled } = useGetFeatureFlag(
    EFeatureFlags.FamilyMembersEnabled
  );

  const selectedForms: TForms = useAppSelector(
    selectInOfficePatientSelectedForms(patient?.id)
  );
  const submittedForms: TForms = useAppSelector(
    selectInOfficePatientSubmittedForms(patient?.id)
  );
  const packageName: string = useAppSelector(
    selectInOfficePackageName(patient?.id)
  );
  const sendTo: EFormAssignmentNotificationType = useAppSelector(
    selectInOfficePatientSendTo(patient?.id)
  );
  const appointmentId: string = useAppSelector(
    selectInOfficePatientAppointmentId(patient?.id)
  );
  const familyMembersWithForms: IPatientFamilyMember[] = useAppSelector(
    selectFamilyMembersWithForms({ patientTabList })
  );
  const familyMembersWithoutForms: IPatientFamilyMember[] = useAppSelector(
    selectFamilyMembersWithoutForms({ patientTabList })
  );
  const submitData: IRequestAssignForms[] = useAppSelector(
    selectSubmitFormsData(patientTabList.map((tab) => tab.id))
  );
  const whereFormAssignerModalOpened: EFormAssignButtons = useAppSelector(
    selectWhereFormAssignerModalOpened
  );
  const isValidFamilyMembersPackageName: boolean = useAppSelector(
    selectIsFamilyMembersPackageNameValid({
      patientTabList,
      mainPatientId: patient?.id,
    })
  );
  const availableSendToOptions: EFormAssignmentNotificationType[] =
    useAppSelector(selectAvailableOptionsSendTo);

  const showCustomPackageName: boolean = useMemo(() => {
    return getShowCustomPackageName(selectedForms, submittedForms);
  }, [selectedForms, submittedForms]);

  const handleCancel = (): void => {
    setVisibility(false);
  };

  const onCloseConfirmModal = (): void => {
    setConfirmModalOpen(false);
  };

  const showSnackbar = (message: string): void => {
    dispatch(updateSnackbar({ status: true, message }));
  };

  const closeModals = (): void => {
    if (!isTransitioningToQR) {
      dispatch(updateIsPatientSelectorModalVisible(false));
      dispatch(updateInOfficeIsFormAssignerModalVisible(false));
      setIsFormAssigning(false);
    }
  };
  const openModalInOfficeVisibility = () => {
    dispatch(updateInOfficeIsFormAssignerModalVisible(true));
  };
  const sendDeviceNotifications = async (requestData: {
    [key: string]: TDeviceNotification[];
  }): Promise<void> => {
    const deviceNotificationsRequests: [string, TDeviceNotification[]][] =
      Object.entries(requestData);

    if (deviceNotificationsRequests.length) {
      try {
        for (const notificationPayload of deviceNotificationsRequests) {
          await sendDeviceNotification({
            deviceId: notificationPayload[0],
            patientId: submitData[0]?.patientId,
            payload: notificationPayload[1],
          });
        }
      } catch (e) {
        closeModals();
      }
    }
  };

  const assignNewForms = async (data): Promise<{ id: string }> => {
    return await assignForms(data).unwrap();
  };

  const updateAssignedForms = async (data): Promise<{ id: string }> => {
    return await saveAssignedForms(data).unwrap();
  };

  const submitForms = async (): Promise<void> => {
    const deviceRequestPayloads = {};
    setIsFormAssigning(true);

    for (const familyMemberData of submitData) {
      try {
        const requestInfo = getRequestInfo(familyMemberData);
        const response = await requestInfo.callback(requestInfo.payload);

        handleSnackbarNotification(whereFormAssignerModalOpened);

        if (shouldSendNotification(familyMemberData)) {
          await sendNotificationRequest(familyMemberData, response.id);
        } else if (shouldSendQRCode(familyMemberData)) {
          await handleQRCodeNotification(familyMemberData, response.id);
        } else {
          collectDeviceNotifications(
            deviceRequestPayloads,
            familyMemberData,
            response.id
          );
        }
      } catch (error) {
        closeModals();
      }
    }

    await sendDeviceNotifications(deviceRequestPayloads);
    finalizeSubmission();
  };

  // Helper Functions
  const getRequestInfo = (familyMemberData: IRequestAssignForms) => {
    const commonPayload = {
      packageName: familyMemberData.packageName || "Custom Package",
      patientId: familyMemberData.patientId,
      forms: familyMemberData.forms,
      appointmentId: familyMemberData.appointmentId,
    };

    return familyMemberData.haveAssignedForms ||
      familyMemberData.formAssignmentId
      ? {
          callback: updateAssignedForms,
          payload: {
            ...commonPayload,
            formAssignmentId: familyMemberData.formAssignmentId,
          },
        }
      : {
          callback: assignNewForms,
          payload: {
            ...commonPayload,
            pendingPatientCheckIn: familyMemberData.pendingPatientCheckIn,
          },
        };
  };

  const handleSnackbarNotification = (modalType: EFormAssignButtons) => {
    showSnackbar(snackbarMessagesMapper[modalType]);
  };

  const shouldSendNotification = (familyMemberData: IRequestAssignForms) =>
    familyMemberData.sendTo !== EFormAssignmentNotificationType.Device &&
    familyMemberData.sendTo !== EFormAssignmentNotificationType.QRCode;

  const sendNotificationRequest = async (
    familyMemberData: IRequestAssignForms,
    formAssignmentId: string
  ) => {
    await sendNotification({
      patientId: familyMemberData.patientId,
      formAssignmentId,
      type: familyMemberData.sendTo,
    }).unwrap();
  };

  const shouldSendQRCode = (familyMemberData: IRequestAssignForms) =>
    familyMemberData.sendTo === EFormAssignmentNotificationType.QRCode &&
    FormAssignmentQrCodeOptionEnabled;

  const handleQRCodeNotification = async (
    familyMemberData: IRequestAssignForms,
    formAssignmentId: string
  ) => {
    const qrCodePayload = [
      { patientId: familyMemberData.patientId, formAssignmentId },
    ];

    try {
      const qrCodeResponse = await sendQRNotification({
        patientId: familyMemberData.patientId,
        payload: qrCodePayload,
      }).unwrap();

      onQRCodeGenerated(qrCodeResponse, patient?.firstName);
      setIsTransitioningToQR(true);
      openModalInOfficeVisibility();
      dispatch(updateIsInOfficeQRCodeModalVisible(true));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error retrieving QR Notification", err);
    }
  };

  const collectDeviceNotifications = (
    deviceRequestPayloads: Record<string, TDeviceNotification[]>,
    familyMemberData: IRequestAssignForms,
    formAssignmentId: string
  ) => {
    const payload: TDeviceNotification = {
      patientId: familyMemberData.patientId,
      formAssignmentId,
    };

    const deviceId = familyMemberData.deviceId;
    const existingPayloads = deviceRequestPayloads[deviceId] || [];
    deviceRequestPayloads[deviceId] = [...existingPayloads, payload];
  };

  const finalizeSubmission = () => {
    dispatch(updatePageIndex(1));
    patientsListRefetch();
    if (!isTransitioningToQR) {
      closeModals();
    }
  };

  const handleSend = async (): Promise<void> => {
    const isFamilyMembersWithoutFormsExist: boolean =
      familyMembersWithoutForms.length > 0 && isFamilyMembersEnabled;

    if (
      isFamilyMembersWithoutFormsExist &&
      sendTo !== 5 &&
      FormAssignmentQrCodeOptionEnabled
    ) {
      setConfirmModalOpen(true);
      return;
    }

    await submitForms();
  };

  const isSendDisabled: boolean = useMemo((): boolean => {
    let isOnTabWithFamilyMembersWithoutForms = false;
    let isOnTabWithFamilyMembersWithForm = false;
    if (FormAssignmentQrCodeOptionEnabled) {
      const activeTabUserId = patientTabList[tabIndex].id;
      isOnTabWithFamilyMembersWithoutForms = familyMembersWithoutForms.some(
        (user) => user.id === activeTabUserId
      );
      isOnTabWithFamilyMembersWithForm = familyMembersWithForms.some(
        (user) => user.id === activeTabUserId
      );
    }
    if (isOnTabWithFamilyMembersWithForm) return false;
    if (isOnTabWithFamilyMembersWithoutForms) return true;
    if (!availableSendToOptions?.length) return true;
    if (!isValidFamilyMembersPackageName) return true;
    return !selectedForms?.length;
  }, [
    selectedForms,
    packageName,
    showCustomPackageName,
    isValidFamilyMembersPackageName,
    availableSendToOptions,
    patientTabList,
    familyMembersWithForms,
  ]);

  if (isFormAssigning) return <ImageContainer marginTop="90px" />;
  return (
    <ModalBodyView
      patient={patient}
      onClick={handleSend}
      onCancel={handleCancel}
      btnColor={theme.palette.text.secondary}
      disabled={isSendDisabled}
    >
      <SecondaryText pb={2}>
        Attach and send forms to your checked-in patients and family members
      </SecondaryText>
      {patientTabList?.length > 1 && isFamilyMembersEnabled ? (
        <FamilyMemberTabs
          patients={patientTabList}
          dispatch={dispatch}
          setTabIndex={setTabIndex}
        />
      ) : (
        <InOfficePatientSection
          showCustomPackageName={showCustomPackageName}
          storedPackageName={packageName}
          sendTo={sendTo}
          appointmentId={appointmentId}
          patientId={patient?.id}
        />
      )}
      <ConfirmFamilyMembersWithoutFormsModal
        isOpen={isConfirmModalOpen}
        onClose={onCloseConfirmModal}
        familyMembersWithForms={familyMembersWithForms}
        familyMembersWithoutForms={familyMembersWithoutForms}
        onPrimaryButtonClick={submitForms}
      />
    </ModalBodyView>
  );
};

export const InOfficeModalBody = React.memo(ModalBody, areEqualProps);
