import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { useGetPatientSummaryQuery } from "../../../../global/services/patientsApi";

import {
  selectAvailableOptionsSendTo,
  updateAvailableOptionsSendTo,
  updateSelectedDevice,
} from "../common/patientSelectorModal/patientSelectorSlice";
import { selectInOfficePatientSendTo } from "../inOfficePatients/FormAssignerModal/inOfficeModalSlice";
import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { ISendToWrapper, IPatientInformation } from "./SendTo.interfaces";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../global/store";
import { ENecessaryPatientFields } from "./ENecessaryPatientFields";
import { InOfficePatientsSendTo } from "./InOfficePatientsSendTo";
import { PendingFormsSendTo } from "./PendingFormsSendTo";
import { EFormAssignmentNotificationType } from "../../../../global/enums/EFormAssignmentNotificationType";
import { MOBILE } from "./constants";
import { useGetFormAssignmentModalDataQuery } from "../../../../global/services/document-manager/formAssignmentModalDataApi";
import { EFeatureFlags } from "../../../../global/enums/EFeatureFlags";
import { useGetFeatureFlag } from "../../../../global/hooks/useGetFeatureFlag";

const SendToWrapper = ({
  patientId,
  isRequestForm,
  updateSendTo,
  isRequiredDoB,
}: ISendToWrapper) => {
  const { isEnabled: isRelease_20250115 } = useGetFeatureFlag(
    EFeatureFlags.Release_20250115
  );
  const storedAvailableOptions: EFormAssignmentNotificationType[] =
    useAppSelector(selectAvailableOptionsSendTo);
  const dispatch: AppDispatch = useAppDispatch();
  const { watch, getValues, setValue } = useFormContext();
  const { data: patientData } = useGetFormAssignmentModalDataQuery(
    { patientId },
    {
      skip: !patientId,
    }
  );
  const { data: patient } = useGetPatientSummaryQuery(patientId);
  const sendToFromState = useAppSelector((state) =>
    selectInOfficePatientSendTo(patientId)(state)
  );
  const email: string = patient?.email;
  const dob: string = patient?.dob;
  const mobile: string = patient?.phones?.find(
    (phone) => phone?.phoneType === MOBILE
  )?.phone;
  const isDobValid = !isRequiredDoB || Boolean(dob);

  useEffect(() => {
    return () => {
      dispatch(updateAvailableOptionsSendTo([]));
    };
  }, []);

  const addMobileToMissedFields = (missedFields: string[]): void => {
    !mobile && missedFields.push(ENecessaryPatientFields.MOBILE_NUMBER);
  };

  const addDobToMissedFields = (missedFields: string[]): void => {
    isRequiredDoB && !dob && missedFields.push(ENecessaryPatientFields.DOB);
  };

  const addEmailToMissedFields = (missedFields: string[]): void => {
    !email && missedFields.push(ENecessaryPatientFields.EMAIL);
  };

  const patientTextInformation: IPatientInformation =
    useMemo((): IPatientInformation => {
      const missedFields: string[] = [];
      addMobileToMissedFields(missedFields);
      addDobToMissedFields(missedFields);

      return {
        disabled: !(isDobValid && Boolean(mobile)),
        missedFields,
      };
    }, [mobile, dob, isDobValid, isRequiredDoB]);

  const patientEmailInformation: IPatientInformation =
    useMemo((): IPatientInformation => {
      const missedFields: string[] = [];
      addEmailToMissedFields(missedFields);
      addDobToMissedFields(missedFields);

      return {
        disabled: !(isDobValid && Boolean(email)),
        missedFields,
      };
    }, [email, dob, isDobValid, isRequiredDoB]);
  const patientsSendTo = useAppSelector(selectInOfficePatientSendTo(patientId));
  const patientTextAndEmailInformation: IPatientInformation =
    useMemo((): IPatientInformation => {
      const missedFields: string[] = [];
      addEmailToMissedFields(missedFields);
      addMobileToMissedFields(missedFields);
      addDobToMissedFields(missedFields);

      return {
        disabled:
          patientTextInformation.disabled || patientEmailInformation.disabled,
        missedFields,
      };
    }, [
      patientTextInformation,
      patientEmailInformation,
      isRequiredDoB,
      email,
      mobile,
      dob,
    ]);

  const onChangeSendTo = (value: EFormAssignmentNotificationType): void => {
    if (updateSendTo) {
      dispatch(updateSendTo(value));
    }
    if (value === 3) {
      const selectedDevice = watch("selectedDevice");
      if (selectedDevice) {
        dispatch(updateSelectedDevice(selectedDevice));
      }
    }
  };

  useEffect(() => {
    if (isRelease_20250115) {
      handleSendToInitialization();
    }
  }, [
    isRelease_20250115,
    patientsSendTo,
    getValues,
    setValue,
    dispatch,
    patientData,
    sendToFromState,
    updateSendTo,
    storedAvailableOptions,
    patientId,
  ]);
  function handleSendToInitialization() {
    const currentSendTo = getValues("sendTo");
    if (!currentSendTo) return;

    if (patientsSendTo) {
      updateSendToValue(patientsSendTo);
    } else if (hasNotifications(patientData)) {
      processNotifications(patientData);
    } else {
      handleDefaultOption();
    }
  }

  function updateSendToValue(value: EFormAssignmentNotificationType) {
    setValue("sendTo", value); // Updates the local form state
    dispatch(updateSendTo(value)); // Updates the global Redux state
  }

  function hasNotifications(data: any): boolean {
    return !!data?.data?.[0]?.formAssignment?.notifications?.length;
  }

  function processNotifications(data: any) {
    try {
      const lastDeviceSelection =
        data.data[0].formAssignment.notifications.at(-1);
      const isValidType =
        lastDeviceSelection?.type in EFormAssignmentNotificationType;

      if (!sendToFromState && updateSendTo && isValidType) {
        const device =
          EFormAssignmentNotificationType[lastDeviceSelection.type];
        const deviceNumber = EFormAssignmentNotificationType[device];
        if (deviceNumber && device) {
          updateDeviceSelection(deviceNumber, device);
        } else {
          logError("Invalid device type:", lastDeviceSelection.type);
        }
      }
    } catch (err) {
      logError("Error processing form assignments:", err);
    }
  }

  function updateDeviceSelection(deviceNumber: number, device: string) {
    dispatch(updateSendTo(deviceNumber));
    setValue("sendTo", deviceNumber);
    dispatch(updateSelectedDevice(EFormAssignmentNotificationType[device]));
  }

  function handleDefaultOption() {
    const defaultOption = storedAvailableOptions[0];
    if (defaultOption) {
      updateSendToValue(defaultOption);
    }
  }

  function logError(message: string, data: any) {
    // eslint-disable-next-line no-console
    console.error(message, data);
  }

  useEffect(() => {
    if (!isRelease_20250115) {
      if (!getValues("sendTo") && patientsSendTo) {
        setValue("sendTo", patientsSendTo);
        dispatch(updateSendTo(patientsSendTo));
      }
    }
  }, [patientsSendTo, getValues, setValue, dispatch]);

  useEffect(() => {
    if (!isRelease_20250115) {
      if (!hasNotifications(patientData)) return;

      try {
        handleFormAssignment(patientData);
      } catch (err) {
        logError("No formAssignments yet", err);
      }
    }
  }, [patientData, sendToFromState, updateSendTo]);

  function handleFormAssignment(data: any) {
    const formAssignment = data?.data?.[0]?.formAssignment;
    if (!formAssignment) {
      logInfo("No data or formAssignment info yet.");
      return;
    }

    const lastDeviceSelection = formAssignment.notifications?.at(-1);
    if (
      !sendToFromState &&
      updateSendTo &&
      lastDeviceSelection &&
      isValidNotificationType(lastDeviceSelection.type)
    ) {
      updateDeviceSelectionOnly(lastDeviceSelection.type);
    }
  }

  function isValidNotificationType(type: string): boolean {
    return type in EFormAssignmentNotificationType;
  }

  function updateDeviceSelectionOnly(type: string) {
    const device = EFormAssignmentNotificationType[type];
    const deviceNumber = EFormAssignmentNotificationType[device];
    if (deviceNumber) {
      dispatch(updateSendTo(EFormAssignmentNotificationType[deviceNumber]));
    } else {
      logError("Invalid type: ", type);
    }
  }

  function logInfo(message: string) {
    // eslint-disable-next-line no-console
    console.info(message);
  }

  return isRequestForm ? (
    <PendingFormsSendTo
      patientTextInformation={patientTextInformation}
      patientEmailInformation={patientEmailInformation}
      patientTextAndEmailInformation={patientTextAndEmailInformation}
      dispatch={dispatch}
      onChange={onChangeSendTo}
      setValue={setValue}
    />
  ) : (
    <InOfficePatientsSendTo
      patientId={patientId}
      dispatch={dispatch}
      setValue={setValue}
      watch={watch}
      patientTextInformation={patientTextInformation}
      patientEmailInformation={patientEmailInformation}
      onChange={onChangeSendTo}
    />
  );
};

export const SendTo = React.memo(SendToWrapper, areEqualProps);
